@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Light.woff2') format('woff2'),
    url('Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-BoldItalic.woff2') format('woff2'),
    url('Rubik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Bold.woff2') format('woff2'),
    url('Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-BlackItalic.woff2') format('woff2'),
    url('Rubik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Black.woff2') format('woff2'),
    url('Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Italic.woff2') format('woff2'),
    url('Rubik-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-LightItalic.woff2') format('woff2'),
    url('Rubik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('Sora-Regular.woff2') format('woff2'),
    url('Sora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Medium.woff2') format('woff2'),
    url('Rubik-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Regular.woff2') format('woff2'),
    url('Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-MediumItalic.woff2') format('woff2'),
    url('Rubik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
