/* ./src/index.css */
@import url('./fonts/stylesheet.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .width-job-modal {
    width: calc(100vw - theme('spacing.16'));
  }
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.dot {
  @apply w-1 h-1 my-3 mx-1 bg-gray-400 rounded-full;
}


.sc-jrsJWt::-webkit-scrollbar, 
#journal-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  border-radius: 20px;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}

.sc-jrsJWt::-webkit-scrollbar-track,
#journal-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  border-radius: 20px;
  /*background: red;*/
}

.sc-jrsJWt::-webkit-scrollbar-thumb,
#journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0a0a0;
  border-radius: 20px;
  /*outline: 1px solid slategrey;*/
}

.react-trello-board::-webkit-scrollbar {
  height: 5px;
  cursor: pointer;
  border-radius: 20px;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.react-trello-board::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  border-radius: 20px;
  /*background: red;*/
}
.react-trello-board::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0a0a0;
  border-radius: 20px;
  /*outline: 1px solid slategrey;*/
}

input:checked + svg {
  display: block;
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

label:focus-within {
  /* targets the label when the input receives focus */
  color: #21ccab;
}

.floating-view {
  position: absolute;
  bottom: 250px;
  left: 60px;
  background-color: white;
  z-index: 1000 !important;
  min-height: 120px;
  min-width: 200px;
  max-width: 389px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.card-hand:active {
  cursor: grabbing;
}

.smooth-dnd-draggable-wrapper {
  cursor: grabbing;
}

.board-height {
  height: calc(100vh - 100px);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  border: 0 !important;
  box-shadow: 0 0 20px #aaa !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 0 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #677f91 !important;
}

.react-datepicker__header--time {
  display: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box::-webkit-scrollbar {
  width: 4px !important;
  cursor: pointer !important;
  border-radius: 20px !important;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity)) !important;
  cursor: pointer !important;
  border-radius: 20px !important;
  /*background: red;*/
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  background-color: #a0a0a0 !important;
  border-radius: 20px !important;
  /*outline: 1px solid slategrey;*/
}

.react-datepicker__day {
  color: #1b4564 !important;
}

.react-datepicker__day--disabled {
  color: #a0a0a0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #21ccab !important;
  color: white !important;
  border-radius: 3rem !important;
}

.kTEdEo {
  margin-top: 0 !important;
}

div[class^='makeStyles-container-'],
div[class*='makeStyles-container-'],
.jss8 {
  overflow-y: initial !important;
}

div[class^='Cell-today-'],
div[class*='Cell-today-'],
.jss30 {
  background-color: #21ccab !important;
}

.profile:hover .invite {
  opacity: 100 !important;
}

.gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#21ccab+1,49c6e5+100 */
  background: #21ccab; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #21ccab 1%,
    #49c6e5 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #21ccab 1%,
    #49c6e5 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #21ccab 1%,
    #49c6e5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#21ccab', endColorstr='#49c6e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.gradient-30 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c8edf7+0,bcefe6+100 */
  background: rgb(200, 237, 247); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(200, 237, 247, 1) 0%,
    rgba(188, 239, 230, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(200, 237, 247, 1) 0%,
    rgba(188, 239, 230, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(200, 237, 247, 1) 0%,
    rgba(188, 239, 230, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c8edf7', endColorstr='#bcefe6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
